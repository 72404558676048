<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer
      :expand-on-hover="expandOnHover"
    />

    <div class="alerts">
      <v-alert
        v-for="([alertId, alert], index) in alertList"
        v-show="alert.show"
        :key="`alert:${index}`"
        prominent
        dark
        :type="alert.type"
        border="top"
        transition="scale-transition"
      >
        <v-row
          align="center"
          no-gutters
        >
          <v-col class="grow">
            <h3 class="font-weight-regular align-center">
              {{ alert.text }}
            </h3>
          </v-col>
          <v-col class="shrink">
            <v-btn
              icon
              @click="removeAlert(alertId)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <dashboard-core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',
    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },
    data: () => ({
      expandOnHover: false,
      alertList: [],
    }),
    mounted () {
      this.$bus.$on('alert', this.newAlert)
    },
    methods: {
      newAlert: function ({ type = 'error', text, timeout = 3000 }) {
        if (!text) {
          text = type === 'error' ? 'Ocorreu um erro. Por Favor tente novamente.'
            : type === 'success' ? 'Sucesso!' : ''
        }
        const alertId = `alert.${type}:${Date.now()}`

        const alertListObj = Object.fromEntries(this.alertList)
        alertListObj[alertId] = {
          id: alertId,
          show: true,
          text: text,
          type: type,
          timeout: timeout,
        }
        this.alertList = Object.entries(alertListObj)

        setTimeout(() => this.removeAlert(alertId), timeout)
      },
      removeAlert: function (alertId) {
        const alertListObj = Object.fromEntries(this.alertList)
        delete alertListObj[alertId]
        this.alertList = Object.entries(alertListObj)
      },
    },
  }
</script>

<style scoped>
  .alerts {
    position: fixed;
    z-index: 999999;
    right: 20px;
    min-width: 40%;
    bottom: 30px;
  }
</style>
